import React from 'react';

import { useTheme } from '@involve-software/uikit';

import * as Styled from './index.styled';

const NotFoundHeader = () => {
  const { images } = useTheme();

  return (
    <Styled.NotFoundHeader>
      <Styled.NotFountLogoLink href="/">
        <img src={images.logo.path} alt="" />
      </Styled.NotFountLogoLink>
    </Styled.NotFoundHeader>
  );
};

export default NotFoundHeader;

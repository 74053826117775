import { Link } from 'react-navi';

import styled from 'styled-components/macro';

import { device } from '../global/MediaQuery';

const NOT_FOUND_HEADER_HEIGHT = '75px';
const NOT_FOUND_HEADER_MOBILE_HEIGHT = '48px';

export const NotFoundLayout = styled.div`
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NotFoundHeader = styled.header`
  width: 100%;
  max-width: 1268px;
  height: ${NOT_FOUND_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-16']};
  box-sizing: border-box;

  @media (${device.mobileXXL}) {
    height: ${NOT_FOUND_HEADER_MOBILE_HEIGHT};
  }
`;

export const NotFountLogoLink = styled(Link)`
  display: inline-block;
  height: 46px;

  @media (${device.mobileXXL}) {
    height: 40px;
  }

  svg {
    height: 100%;
  }
`;

export const NotFoundBlock = styled.div`
  max-width: 762px;
  margin: 0 auto;
  color: ${({ theme }) => theme.colors['text-primary']};
  text-align: center;
  padding: ${NOT_FOUND_HEADER_HEIGHT}
    ${({ theme }) => theme.spacing.primitives['s-16']};
  box-sizing: border-box;

  svg {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    max-width: 80%;
    height: auto;
  }

  h2 {
    font: ${({ theme }) => theme.typography['display-medium']};
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
    margin-top: 0;

    @media (${device.mobileXXL}) {
      font: ${({ theme }) => theme.typography['heading-medium']};
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
  }

  p {
    font: ${({ theme }) => theme.typography['paragraph-medium']};
    margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-32']};

    @media (${device.mobileXXL}) {
      font: ${({ theme }) => theme.typography['paragraph-small']};
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
    }

    a {
      text-decoration: underline;
      text-decoration-thickness: 0.5px;
    }
  }
`;

export const NotFoundHomeLink = styled(Link)`
  display: inline-block;

  @media (${device.mobileXXL}) {
    display: block;

    button {
      width: 100%;
    }
  }
`;

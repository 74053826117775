import React from 'react';

import { Button, useTheme } from '@involve-software/uikit';

import { Trans, useTranslation } from 'react-i18next';

import { env } from 'helpers/env';

import NotFoundHeader from './NotFoundHeader';
import * as Styled from './index.styled';

export const NotFoundBlock = () => {
  const { t } = useTranslation();
  const { images } = useTheme();

  return (
    <Styled.NotFoundBlock>
      <images.notFoundIcon.component />
      <h2>{t('common:notFound.title')}</h2>
      <p>
        <Trans i18nKey={'common:notFound.text'}>
          It seems the page you were trying to reach doesn't exist, might have
          been removed, or is temporarily unavailable. If you need immediate
          assistance or wish to report this issue, please contact our
          <a href={`mailto:${env.REACT_APP_SUPPORT_MAIL}`}>Support Team</a>.
        </Trans>
      </p>
      <Styled.NotFoundHomeLink href={'/'}>
        <Button>{t('common:notFound.homeButton')}</Button>
      </Styled.NotFoundHomeLink>
    </Styled.NotFoundBlock>
  );
};

export const NotFoundLayout = () => {
  return (
    <Styled.NotFoundLayout>
      <NotFoundHeader />
      <NotFoundBlock />
    </Styled.NotFoundLayout>
  );
};

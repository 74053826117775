import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ScCalendar = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 6px);
  text-align: center;
  width: 288px;
  z-index: 20;
  @media (${device.desktop}) {
    margin: 0;
    top: ${({ theme }) => theme.spacing.primitives['s-04']};
  }
  .input--error {
    border-color: ${({ theme }) => theme.colors['text-danger']};
  }
  .error {
    text-align: left;
    ${({ theme }) => theme.typography['paragraph-x-small']};
    color: ${({ theme }) => theme.colors['text-primary-inverted']};
    padding: ${({ theme }) => theme.spacing.primitives['s-12']}
      ${({ theme }) => theme.spacing.primitives['s-08']};
    background: ${({ theme }) => theme.colors['background-01-inverted']};
    border-radius: ${({ theme }) => theme.radius['radius-pr-subtle']};
    position: absolute;
    width: 175px;
    box-sizing: border-box;
    right: calc(100% - ${({ theme }) => theme.spacing.primitives['s-16']});
    top: ${({ theme }) => theme.spacing.primitives['s-12']};
    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid
        ${({ theme }) => theme.colors['background-01-inverted']};
      left: calc(100% - ${({ theme }) => theme.spacing.primitives['s-01']});
      top: calc(50% - ${({ theme }) => theme.spacing.primitives['s-10']});
      @media (${device.tablet}) {
        display: none;
      }
    }
    @media (${device.tablet}) {
      right: 0;
      bottom: 0;
      width: 100%;
      height: 30px;
      top: calc(100% - ${({ theme }) => theme.spacing.primitives['s-12']});
    }
  }
  .big-line {
    min-width: 10px;
    height: 1px;
    background: ${({ theme }) => theme.colors['text-primary']};
    margin: 0 ${({ theme }) => theme.spacing.primitives['s-04']};
  }
  .time-text {
    margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
  }
  input {
    border: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
    border-radius: ${({ theme }) => theme.radius['radius-fields']};
    padding: ${({ theme }) => theme.spacing.primitives['s-12']};
    font: ${({ theme }) => theme.typography['label-small']};
    color: ${({ theme }) => theme.colors['text-primary']};
    overflow: hidden;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
`;
export const ScInputBlock = styled.div<{ footer?: true }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.primitives['s-16']}
    ${({ theme }) => theme.spacing.primitives['s-28']};
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['label-small']};
  ${({ footer, theme }) =>
    footer
      ? `border-top: 1px solid ${theme.colors['border-subtle-01']};border-bottom: 1px solid ${theme.colors['border-subtle-01']}`
      : null};
`;

export const ScMonth = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.primitives['s-24']}
    ${({ theme }) => theme.spacing.primitives['s-16']};
  font: ${({ theme }) => theme.typography['label-medium']};
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors['text-primary']};
  .rotate-right {
    transform: rotate(180deg);
  }
  li {
    height: 24px;
    min-width: 24px;
    button {
      border: 0;
      cursor: pointer;
      margin: 0;
      padding: 0;
      background: transparent;
      & > svg {
        height: 24px;
        width: 24px;
        path {
          fill: ${({ theme }) => theme.colors['icon-primary']};
        }
      }
    }
  }
`;
export const ScDaysHeader = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-16']};
  display: flex;
  align-items: center;
  justify-content: space-between;
  li {
    font: ${({ theme }) => theme.typography['label-x-small']};
    text-transform: capitalize;
    color: ${({ theme }) => theme.colors['text-subtle']};
  }
`;

export const ScDays = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(7, 39px);
  margin: ${({ theme }) => theme.spacing.primitives['s-04']}
    ${({ theme }) => theme.spacing.primitives['s-08']};
  padding: 0;
  li {
    font: ${({ theme }) => theme.typography['label-small']};
    color: ${({ theme }) => theme.colors['text-primary']};
    border-radius: ${({ theme }) => theme.radius['radius-cards-strong']};
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
    &:hover {
      background: ${({ theme }) => theme.colors['field-01-hover']};
    }
  }
  .range {
    background: ${({ theme }) => theme.colors['fill-10']};
    &--selected {
      background: ${({ theme }) => theme.colors['fill-02']};
      &:hover {
        background: ${({ theme }) => theme.colors['fill-03']};
      }
    }
    &--selected {
      background: ${({ theme }) => theme.colors['disabled']};
    }
    &--start {
      background: ${({ theme }) => theme.colors['fill-02']};
      border-bottom-left-radius: ${({ theme }) =>
        theme.radius['radius-pr-strong']};
      border-top-left-radius: ${({ theme }) =>
        theme.radius['radius-pr-strong']};
      &:hover {
        background: ${({ theme }) => theme.colors['fill-02']};
      }
    }
    &--end {
      background: ${({ theme }) => theme.colors['fill-02']};
      border-bottom-right-radius: ${({ theme }) =>
        theme.radius['radius-pr-strong']};
      border-top-right-radius: ${({ theme }) =>
        theme.radius['radius-pr-strong']};
      &:hover {
        background: ${({ theme }) => theme.colors['fill-02']};
      }
    }
    &--prev {
      color: ${({ theme }) => theme.colors['text-subtle']};
    }
    &--disabled {
      color: ${({ theme }) => theme.colors['text-placeholder']};
      cursor: default;
      pointer-events: none;
      &:checked {
        background-color: ${({ theme }) => theme.colors['button-disabled-bg']};
      }
    }
  }
`;

export const ScCalendarWrap = styled.div`
  padding: ${({ theme }) => theme.spacing.primitives['s-16']} 0;
  display: flex;
  align-items: center;
  justify-content: center;
  & > *:first-child {
    margin-right: ${({ theme }) => theme.spacing.primitives['s-10']};
  }
`;

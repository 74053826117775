import { device } from 'components/global/MediaQuery';

import { PaymentProcessingStatus } from 'modules/massPayments/utils';

import styled from 'styled-components/macro';

export function hexToRGB(hex: string, alpha: number = 1) {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}

const themeMassPayments = {
  PROCESSING: '#959BA4',
  SUCCESSFUL: '#56B447',
  WAITING: '#4D89CC',
  REJECTED: '#D9331D',
  ERROR: '#E8882F',
};

export const ScMassPaymentsLabel = styled.div<{ status: number }>`
  width: auto;
  min-width: 94px;
  border-radius: ${({ theme }) => theme.radius['radius-pr-subtle']};
  font: ${({ theme }) => theme.typography['label-x-small']};
  text-align: center;
  color: ${({ status }) =>
    (status === PaymentProcessingStatus.PROCESSING &&
      themeMassPayments.PROCESSING) ||
    (status === PaymentProcessingStatus.SUCCESSFUL &&
      themeMassPayments.SUCCESSFUL) ||
    (status === PaymentProcessingStatus.WAITING && themeMassPayments.WAITING) ||
    (status === PaymentProcessingStatus.REJECTED &&
      themeMassPayments.REJECTED) ||
    (status === PaymentProcessingStatus.ERROR && themeMassPayments.ERROR)};
  background-color: ${({ status }) =>
    (status === PaymentProcessingStatus.PROCESSING &&
      hexToRGB(themeMassPayments.PROCESSING, 0.2)) ||
    (status === PaymentProcessingStatus.SUCCESSFUL &&
      hexToRGB(themeMassPayments.SUCCESSFUL, 0.2)) ||
    (status === PaymentProcessingStatus.WAITING &&
      hexToRGB(themeMassPayments.WAITING, 0.2)) ||
    (status === PaymentProcessingStatus.REJECTED &&
      hexToRGB(themeMassPayments.REJECTED, 0.2)) ||
    (status === PaymentProcessingStatus.ERROR &&
      hexToRGB(themeMassPayments.ERROR, 0.2))};
`;

export const ScMassPaymentProcessing = {
  Block: styled.div`
    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing.primitives['s-28']};
  `,

  Progress: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
  `,

  ProgressTitle: styled.span`
    font-weight: normal;
    font: ${({ theme }) => theme.typography['label-x-small']};
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-10']};
    color: ${({ theme }) => theme.colors['text-secondary']};
  `,

  ProgressLine: styled.div<{
    processing: number;
    successful: number;
    waiting: number;
    rejected: number;
    error: number;
    total: number;
  }>`
    position: relative;
    display: flex;
    width: 100%;
    height: 5px;
    overflow: hidden;
  `,

  ProgressLineItem: styled.div<{
    left: number;
    right: number;
    status: number;
  }>`
    position: absolute;
    top: 0;
    bottom: 0;
    left: ${({ left }) => left}%;
    right: ${({ right }) => 100 - right}%;

    background-color: ${({ status }) =>
      (status === PaymentProcessingStatus.PROCESSING &&
        themeMassPayments.PROCESSING) ||
      (status === PaymentProcessingStatus.SUCCESSFUL &&
        themeMassPayments.SUCCESSFUL) ||
      (status === PaymentProcessingStatus.WAITING &&
        themeMassPayments.WAITING) ||
      (status === PaymentProcessingStatus.REJECTED &&
        themeMassPayments.REJECTED) ||
      (status === PaymentProcessingStatus.ERROR && themeMassPayments.ERROR)};
    z-index: ${({ status }) => {
      switch (status) {
        case PaymentProcessingStatus.PROCESSING:
          return 0;
        case PaymentProcessingStatus.SUCCESSFUL:
        case PaymentProcessingStatus.WAITING:
        case PaymentProcessingStatus.REJECTED:
        case PaymentProcessingStatus.ERROR:
          return 5 - status;
        default:
          return;
      }
    }};

    transition: all 500ms linear;
  `,

  Status: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
    @media (max-width: 767px) {
      justify-content: space-evenly;
    }
  `,

  StatusItem: styled.div`
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 767px) {
      padding: ${({ theme }) => theme.spacing.primitives['s-04']} 0;
      width: calc(100% / 3);
    }
    @media (max-width: 580px) {
      width: 50%;
    }
  `,

  StatusCount: styled.div`
    font: ${({ theme }) => theme.typography['heading-medium']};
  `,

  StatusLabel: styled(ScMassPaymentsLabel)`
    margin-top: ${({ theme }) => theme.spacing.primitives['s-08']};
  `,

  Actions: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  `,

  Action: styled.a`
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    color: ${({ theme }) => theme.colors['text-link']};
    font: ${({ theme }) => theme.typography['underlined-medium']};
    transition: color 300ms ease;
    &:hover {
      cursor: pointer;
    }
    &:last-child {
      margin-bottom: 0;
    }
    & svg {
      width: 20px;
      height: 20px;
      margin-right: ${({ theme }) => theme.spacing.primitives['s-08']};
      fill: ${({ theme }) => theme.colors['text-link']};
      transition: fill 300ms ease;
    }
  `,
};

export const ScMassPaymentProcessingDetails = {
  Block: styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${({ theme }) => theme.spacing.primitives['s-28']}
      ${({ theme }) => theme.spacing.primitives['s-28']};
  `,

  BlockItem: styled.div`
    padding: ${({ theme }) => theme.spacing.primitives['s-20']}
      ${({ theme }) => theme.spacing.primitives['s-12']};
    border-radius: ${({ theme }) => theme.radius['radius-cards-strong']};
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-08']};
    background-color: ${({ theme }) => theme.colors['layer-03']};
    border: 1px solid transparent;
    transition: all 0.3s ease;
    cursor: pointer;
    &:hover {
      border-color: ${({ theme }) => theme.colors['border-main']};
    }
  `,
  BlockItemContainer: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    @media (${device.tablet}) {
      flex-direction: column;
      align-items: center;
    }
  `,
  BlockItemTitle: styled.div`
    max-width: 300px;
    display: flex;
    align-items: flex-start;
    margin-right: auto;
    @media (${device.tablet}) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
      max-width: 100%;
    }
    @media (${device.mobileM}) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
      max-width: 100%;
    }
  `,
  BlockItemTitleCounter: styled.div`
    min-width: 35px;
    margin-right: ${({ theme }) => theme.spacing.primitives['s-20']};
    font: ${({ theme }) => theme.typography['heading-x-small']};
    @media (${device.tablet}) {
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
      min-width: auto;
    }
  `,

  BlockItemTitleBox: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (${device.tablet}) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  `,

  BlockItemTitleMain: styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-08']};
    font: ${({ theme }) => theme.typography['heading-x-small']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (${device.tablet}) {
      white-space: normal;
      text-align: center;
      width: 100%;
    }
  `,
  BlockItemTitleSecondary: styled.div`
    font: ${({ theme }) => theme.typography['paragraph-small']};
    color: ${({ theme }) => theme.colors['text-subtle']};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (${device.tablet}) {
      white-space: normal;
    }
    @media (${device.mobileL}) {
      white-space: normal;
      text-align: justify;
      overflow: auto;
      text-overflow: inherit;
    }
  `,
  BlockItemSummary: styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 0 ${({ theme }) => theme.spacing.primitives['s-16']};
    font: ${({ theme }) => theme.typography['heading-x-small']};
    white-space: nowrap;
    @media (${device.tablet}) {
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-20']};
    }
  `,

  // @ts-ignore
  BlockItemStatus: styled(ScMassPaymentsLabel)`
    margin-left: ${({ theme }) => theme.spacing.primitives['s-16']};
    @media (${device.tablet}) {
      margin-left: 0;
    }
  `,

  BlockItemDropdown: styled.div<{ isVisible?: boolean }>`
    display: flex;
    max-height: ${({ isVisible }) => (isVisible ? '1000px' : 0)};
    flex-direction: column;
    padding: ${({ isVisible, theme }) =>
      isVisible
        ? `${theme.spacing.primitives['s-28']} 0 0 ${theme.spacing.primitives['s-56']}`
        : `0 0 0 ${theme.spacing.primitives['s-56']}`};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: all 300ms ease;
    overflow-x: hidden;
    overflow-y: auto;

    @media (${device.tablet}) {
      padding: ${({ theme }) => theme.spacing.primitives['s-28']} 0 0 0;
    }
  `,

  BlockItemDropdownContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-28']};
    &:last-child {
      margin-bottom: 0;
    }
  `,

  BlockItemDropdownItem: styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    &:last-child {
      margin-bottom: 0;
    }
    @media (${device.tablet}) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  `,

  BlockItemDropdownTitle: styled.div`
    width: 100%;
    max-width: 150px;
    margin-right: ${({ theme }) => theme.spacing.primitives['s-28']};
    font: ${({ theme }) => theme.typography['label-medium']};
    color: ${({ theme }) => theme.colors['text-secondary']};
    @media (${device.tablet}) {
      width: auto;
    }
  `,

  BlockItemDropdownSubtitle: styled.div`
    font: ${({ theme }) => theme.typography['label-medium']};
    color: ${({ theme }) => theme.colors['text-primary']};
    span {
      overflow-wrap: anywhere;
    }
  `,
};

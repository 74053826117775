import {
  MassCryptoPaymentsInvalid,
  MassCryptoPaymentsUpload,
  MassFiatPaymentsInvalid,
  MassFiatPaymentsUpload,
} from 'api/shop/massPayments';

import { ShopCryptoPaymentStatus, ShopPaymentStatus } from 'helpers/enums';

export function isValidPayment(
  payment:
    | MassFiatPaymentsUpload
    | MassCryptoPaymentsUpload
    | MassCryptoPaymentsInvalid
    | MassFiatPaymentsInvalid
): payment is MassFiatPaymentsUpload | MassCryptoPaymentsUpload {
  return !Object.prototype.hasOwnProperty.call(payment, 'errorCode');
}

export enum PaymentProcessingStatus {
  PROCESSING,
  SUCCESSFUL,
  WAITING,
  REJECTED,
  ERROR,
}

export const paymentProcessingStatusMapping = {
  [PaymentProcessingStatus.PROCESSING]:
    'massPayments:progressStatus.processing',
  [PaymentProcessingStatus.SUCCESSFUL]:
    'massPayments:progressStatus.successful',
  [PaymentProcessingStatus.WAITING]: 'massPayments:progressStatus.waiting',
  [PaymentProcessingStatus.REJECTED]: 'massPayments:progressStatus.rejected',
  [PaymentProcessingStatus.ERROR]: 'massPayments:progressStatus.error',
};

export function shopPaymentStatusToProcessingStatus(status: ShopPaymentStatus) {
  switch (status) {
    case ShopPaymentStatus.REFUNDED:
    case ShopPaymentStatus.REJECTED:
      return PaymentProcessingStatus.REJECTED;

    case ShopPaymentStatus.SUCCESS:
      return PaymentProcessingStatus.SUCCESSFUL;

    case ShopPaymentStatus.WAITING:
      return PaymentProcessingStatus.WAITING;

    default:
      return PaymentProcessingStatus.ERROR;
  }
}

export function shopCryptoPaymentProcessingStatus(
  status: ShopCryptoPaymentStatus
) {
  switch (status) {
    case ShopCryptoPaymentStatus.REJECTED:
      return PaymentProcessingStatus.REJECTED;

    case ShopCryptoPaymentStatus.SUCCESS:
      return PaymentProcessingStatus.SUCCESSFUL;

    case ShopCryptoPaymentStatus.WAITING:
      return PaymentProcessingStatus.WAITING;

    default:
      return PaymentProcessingStatus.ERROR;
  }
}

export function groupByValue(array: number[]) {
  return array.reduce<{ [key: number]: number }>((acc, val) => {
    if (Object.prototype.hasOwnProperty.call(acc, val)) {
      acc[val] += 1;
    } else {
      acc[val] = 1;
    }

    return acc;
  }, {});
}

export const isCryptoPayment = (code: number | string) =>
  typeof code === 'string';

import { i18n } from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

export const fallbackLng = 'en';

export class CustomLanguageDetector extends I18nextBrowserLanguageDetector {
  async = true;
  detect(setLng: any) {
    const detectedLng = super.detect();

    if (detectedLng && detectedLng?.length) {
      setLng(detectedLng);
    }

    return undefined;
  }
}

let i18nInstance: i18n;

export const I18nInstanceSetter = {
  type: '3rdParty' as const,
  init(instance: i18n) {
    i18nInstance = instance;
  },
};

export { i18nInstance };
